<template>
	<div class="flex-col">
		<span v-if="devMode" class="mru-id">{{ mru.id }}</span>
		<h2 class="edit" title="Click to edit title" @click="editBasicDetailsModal()">{{ mru.name }}</h2>
		<p class="edit" title="Click to edit location" @click="editBasicDetailsModal()">{{ mru.location }}</p>
		<button class="btn" @click="viewDetailsModal()" title="View Details">View Details</button>
		<div class="pointer">
			<label :for="`enabled${index}`" :title="isEnabled ? 'Click to disable' : 'Click to enable'">Enabled</label>
			<input
				type="checkbox"
				:name="`Enabled${index}`"
				:id="`enabled${index}`"
				:title="isEnabled ? 'Click to disable' : 'Click to enable'"
				:checked="isEnabled"
				v-model="isEnabled"
			/>
		</div>
		<span v-if="!mru.isEnabled" class="general-status disabled" title="MRU Disabled"></span>
		<span v-else class="general-status" :title="getClassName()" :class="getClassName()"></span>
	</div>
	<dialog id="disable-dialog">
		<div>
			<p id="disable-text">
				{{ `Are you sure you want to disable ${this.mru.name}`}}
			</p>
			<div id="btn-container">
				<button id="confirmButton" class="btn" value="default" @click="dispatchMruEnable()">Ok</button>
				<button id="cancelButton" class="btn" value="cancel" formmethod="dialog" @click="handleCheckboxAndCloseModal()">Cancel</button>
			</div>
		</div>
	</dialog>
</template>

<script>
export default {
	name: "MruStatusInstance",
	inheritAttrs: false,
	props: {
		mru: Object,
		index: Number,
	},
	data() {
		return {
			errorStates: ["Offline", "NotUsed", "Good", "NeedsAttendant", "Error"],
			stateClassNames: {
				Offline: "offline",
				NotUsed: "not-used",
				Good: "good",
				NeedsAttendant: "needs-attendant",
				Error: "error",
			},
			isEnabled: this.mru.isEnabled,
			checkedIn: Object.keys(this.mru.mruStatus).length > 0,
			inputDialog: null,
		};
	},
	watch: {
		isEnabled() {
			if (this.mru.isEnabled !== this.isEnabled) {
				this.showDisableDialog();
			}
		},
		mru: {
			handler() {
				this.isEnabled = this.mru.isEnabled;
				this.checkedIn = Object.keys(this.mru.mruStatus).length > 0;
			},
			deep: true,
		},
	},
	mounted() {
		this.inputDialog = document.getElementById("disable-dialog");
	},
	methods: {
		getClassName() {
			let className = "";
			if (Object.keys(this.mru.mruStatus).length > 0) {
				className = this.errorStates.includes(this.mru.mruStatus?.generalStatus)
					? this.stateClassNames[this.mru.mruStatus.generalStatus]
					: "";
			} else {
				className = "status-unknown";
			}
			return className;
		},
		dispatchMruEnable() {
			this.mru.isEnabled = this.isEnabled;
			this.eventBus.emit("updateMruEnabled", this.mru);
		},
		editBasicDetailsModal() {
			this.eventBus.emit("openEditMruDetailsModal", this.mru);
		},
		viewDetailsModal() {
			this.eventBus.emit("openMruDetailsModal", this.mru);
		},

		showDisableDialog() {
			if(this.mru.isEnabled) {

				this.inputDialog.showModal();
			} else {
				
				this.dispatchMruEnable();
			}
		},

		handleCheckboxAndCloseModal() {
			this.isEnabled = this.mru.isEnabled;
			let checkbox = document.getElementById(`enabled${this.index}`);
			checkbox.checked = this.isEnabled;
			this.inputDialog.close();
		}
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.general-status {
	margin: 15px auto;
}

.general-status::after {
	content: "";
	font-weight: bold;
	color: white;
	padding: 11px 15px;
	/* line-height: 1em; */
	border-radius: 100%;
	height: 40px;
	width: 40px;
	border: 1px #000 solid;
}

.disabled::after {
	content: "\274C";
	background-color: #000;
	border-radius: 8px;
	padding: 10px;
}

.good::after {
	content: "\2713";
	background-color: green;
}

.error::after,
.warn::after,
.needs-attendant::after,
.status-unknown::after {
	content: "⚠️";
	font-size: 1.375em;
	padding: 6px 8px 12px;
}

.error::after {
	background-color: #f00;
}

.warn::after,
.needs-attendant::after,
.status-unknown::after {
	background-color: yellow;
}

.flex-col {
	align-items: center;
	justify-content: space-between;
	height: 100%;
}

.pointer *,
.edit {
	cursor: pointer;
}

.edit::after {
	content: "\270F";
	transform: rotate(135deg);
	position: absolute;
	color: #9d9d9d;
	transition: color 0.2s;
}

.mru-id {
	user-select: all;
}

#disable-dialog {
	margin: auto;
	background-color: #000;
	color: #fff;
	border-radius: 12px;
	border-width: 1px;
}
::backdrop {
	background-color: #000;
	backdrop-filter: blur(8px);
	opacity: 0.75;
}
#btn-container {
	display: flex;
	justify-content: center;
}

#disable-text {
	text-align: center;
}

/* iOS specific CSS */
@supports (-webkit-touch-callout: none) {
	.edit::after {
		transform: rotate(90deg);
	}
}

.edit:hover::after {
	/* content: "\270F";
	transform: rotate(135deg);
	position: absolute; */
	color: #000;
}
</style>
